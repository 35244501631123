<template>
	<div class="page container">
		<v2-back-btn :route="`/teaching`" />
		<div class="page__info">
			<div class="page__info--title">
				Transactions
			</div>
		</div>
		<transactions
			entryRoute="teaching"
		/>
	</div>
</template>

<route>
	{
		"meta": {
			"isTeacherOnly": true
		}
	}
</route>

<script>

	import Transactions  from '@/components/transactions/Transactions';
	import V2BackBtn from '@/components/v2/ui/V2BackBtn';

	export default {
		metaInfo: {
			title: 'Transactions'
		},
		layout: 'v2default',
		components: {
			Transactions,
			V2BackBtn
		},
		data: () => ({}),
		computed: {},
		mounted () {}
	};
</script>
